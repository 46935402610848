import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "styled-components";
import theme from "./theme";
import GlobalStyles from "./css/styles";

import Header from "./header";
import Footer from "./footer";

const Layout = ({ children, fullPage }) => {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=3"
        ></meta>
      </Helmet>
      <GlobalStyles />
      {!fullPage && <Header />}
      <main className={fullPage && "-fullPage"}>{children}</main>
      {!fullPage && <Footer />}
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
