import styled from "styled-components";

export const Container = styled.section`
  position: relative;
  width: 100%;
  height: 100%;
  /* max-width: 1920px; */
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  background: ${(props) =>
    props.theme.colours[props.bg]
      ? props.theme.colours[props.bg]
      : props.bg
      ? props.bg
      : props.theme.colours.white};
  overflow: hidden;
`;
