import styled from "styled-components";

export const Navigation = styled.header`
  position: relative;
  width: 100%;
  /* max-width: 1920px; */
  max-width: 100%;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px 0;
  background-color: ${(props) => props.theme.colours.white};
  box-shadow: none;

  /* Force smaller padding on mobile */
  > div {
    
    @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
      padding-right: ${(props) => props.theme.spacing.xs};
      padding-left: ${(props) => props.theme.spacing.xs};
    }
  }

  &.main-navigation {
    position: absolute;
    z-index: 99;
    transition: box-shadow ${(props) => props.theme.transitions.med};

    .menu-item {
      padding: 20px;
    }

    &.-sticky {
      position: fixed;
    }

    &.-scrolled {
      box-shadow: ${(props) => props.theme.styles.shadow};
    }
  }
  
  .main-menu {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 98;
    transition: all ${(props) => props.theme.transitions.med};

    @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
      z-index: -1;
      display: block;
      opacity: 0;
      pointer-events: none;
      width: 100%;
      flex-direction: column;
      max-height: 0;
      overflow: hidden;

      &.-active {
        z-index: 98;
        opacity: 1;
        pointer-events: all;
        overflow-y: scroll;
        max-height: calc(100vh - 120px);
        padding-bottom: 30px;
        -webkit-overflow-scrolling: touch;
      }
    }

    .menu-logo {
      height: 90px;
      margin-right: 20px;

      @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
        display: none;
      }
    }

    .menu-left,
    .menu-center,
    .menu-right {
      display: flex;
      align-items: center;
      position: relative;
      z-index: 10;

      @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
        flex-direction: column;
      }
    }

    .menu-left {
      justify-content: flex-start;
    }

    .menu-center {
      justify-content: center;
    }

    .menu-right {
      justify-content: flex-end;

      .dropdown-menu {

        .dropdown-menu-items {
          right: 0;
        }
      }

      /* Sign in button style */
      @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
        .grey.-md {
          width: 95%;
          margin-top: 20px;
        }
      }
    }

    #showOnScroll {
      display: none;
    }

    .menu-item {
      color: ${(props) => props.theme.colours.dark};
      text-decoration: none;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                            supported by Chrome, Opera and Firefox */
      /* transition: all ${(props) => props.theme.transitions.med}; */

      @media(max-width: ${(props) => props.theme.breakpoints.tablet}){
        padding: 20px 12px !important;
      }

      @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
        width: 100%;
        text-align: left;
        border-top: solid 1px rgba(0, 0, 0, 0.05);
      }

      &.-hideEarly {
        @media(max-width: 1120px){
          display: none;
        }

        @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
          display: block;
        }
      }

      &:hover {
        color: ${(props) => props.theme.colours.black};
        background-color: rgba(0, 0, 0, 0.025);
      }

      &.-isLink{
        font-weight: 600;

        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }

        &:active {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }

      &.-noHover{
        &:hover {
          background-color: rgba(0, 0, 0, 0);
        }

        &:active {
          background-color: rgba(0, 0, 0, 0);
        }
      }
    }
  }

  .mobile-menu {
    display: none;
    position: relative;
  
    @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      z-index: 98;
  
      .menu-logo {
        padding-top: 2px;
        padding-bottom: 2px;
        height: 80px;
        margin-right: 30px;
      }
  
      .menu-mobile-buttons {
        height: 59px;
        flex-direction: row !important;
  
        .menu-mobile-cta {
  
          @media(max-width: 400px){
            display: none;
          }
        }
  
        .menu-mobile-open {
          height: 40px;
          width: 40px;
          margin-left: 10px;
          background-color: ${(props) => props.theme.colours.white};
          border-radius: ${(props) => props.theme.styles.borderRadius};
          display: flex;
          align-items: center;
          justify-content: center;

          > svg {
            font-size: 18px;
          }

          &:focus {
            outline: 0;
          }
  
          .mobile-button-icon {
            pointer-events: none;
  
            > * + * {
              margin-top: 3px;
            }
  
            .button-bar {
              will-change: transform, background-color;
              position: relative;
              display: block;
              width: 17px;
              height: 2px;
              border-radius: 40px;
              opacity: 1;
              background-color: ${(props) => props.theme.colours.dark};
              transform: rotate(0deg);
              transition: all ${(props) => props.theme.transitions.med};
            }
  
            &.-isOpen {
  
              .button-bar {
                background-color: ${(props) => props.theme.colours.midDark};
  
                &:first-child {
                  transform: rotate(135deg) translate(4px, -4px);
                }
  
                &:nth-child(2){
                  opacity: 0;
                }
  
                &:last-child{
                  transform: rotate(-135deg) translate(3px, 3px);
                }
              }
            }
          }
        } 
      }   
    }
  }

  .nav-overlay {
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;
    display: block;
    z-index: -1;
    transition: all ${(props) => props.theme.transitions.med};

    &.-active {
      /* display: block; */
      z-index: 0;
      opacity: 1;
      pointer-events: all;
    }

    @media(min-width: ${(props) => props.theme.breakpoints.mobile}){
      display: none !important;
    }
  }
`;
