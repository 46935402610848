import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth + "px" : "1200px")};
  margin-left: auto;
  margin-right: auto;
  padding-top: ${(props) => (props.gap ? props.gap + "px" : "80px")};
  padding-right: ${(props) => props.theme.spacing.xs};
  padding-bottom: ${(props) =>
    !props.noPaddingBottom ? (props.gap ? props.gap + "px" : "80px") : "0"};
  padding-left: ${(props) => props.theme.spacing.xs};
  background: ${(props) => props.theme.colours[props.bg] || "none"};

  > * + * {
    margin-top: ${(props) => (props.gap ? props.gap + "px" : "80px")};
  }

  /* Ensure good gutters on all screen sizes */
  @media (max-width: 1440px) {
    max-width: ${(props) => (props.maxWidth ? props.maxWidth + "px" : "90%")};
  }

  /* Gutters now sized based on padding */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    max-width: 100%;
    padding-top: ${(props) =>
      props.gap ? "calc(" + props.gap + "px / 1.5)" : "75px"};
    padding-right: ${(props) => props.theme.spacing.sm};
    padding-bottom: ${(props) =>
      !props.noPaddingBottom
        ? props.gap
          ? "calc(" + props.gap + "px / 1.5)"
          : "75px"
        : "0"};
    padding-left: ${(props) => props.theme.spacing.sm};

    > * + * {
      margin-top: ${(props) =>
        props.gap ? "calc(" + props.gap + "px / 1.5)" : "75px"};
    }
  }
`;
