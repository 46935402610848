import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import { Container, Wrapper, Row, Box } from "./util";

import Logo from "../images/logo/WPGS_Full_White.svg";

const FooterContainer = styled(Container)`
  color: ${(props) => props.theme.colours.dark};
  background-color: ${(props) => props.theme.colours.white};
  a {
    color: ${(props) => props.theme.colours.dark};
    text-decoration: none;

    > svg {
      top: 2px;
      left: 5px;
    }

    &:hover {
      color: ${(props) => props.theme.colours.Primary};
    }

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      margin-bottom: 4px;
      padding: 5px 0;
    }
  }

  .footer-top {
    a {
      display: table;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      padding: 0 10px;
    }

    hr {
      border: none;
      background: none;
      height: 20px;

      &.-sm {
        height: 10px;
      }

      @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        height: 10px;
      }
    }
  }
`;

const Footer = () => (
  <footer>
    <Container bg="Secondary">
      <Wrapper gap={30}>
        <Row gap={30} alignCenter>
          <Box>
            <Link to="/">
              <img src={Logo} alt="WPGS" height="80" />
            </Link>
          </Box>
          <Box gap={15}>
            <Row justify="flex-end" gap={15} alignCenter>
              <a css={{ color: "#fff" }} href="tel:0272227775">
                027 222 7775
              </a>
              <a css={{ color: "#fff" }} href="mailto:druw@wpgs.co.nz">
                druw@wpgs.co.nz
              </a>
              <a
                css={{ color: "#fff" }}
                target="_blank"
                href="/documents/WPGS_TermsAndConditions.pdf"
              >
                Terms & Conditions
              </a>
            </Row>
            <div css={{ color: "#fff", opacity: "0.5" }}>
              © {new Date().getFullYear()} Whole Plumbing & Gas Solutions All
              rights reserved.
            </div>
          </Box>
        </Row>
      </Wrapper>
    </Container>
  </footer>
);

export default React.memo(Footer);
