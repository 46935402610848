import styled from "styled-components";

export const FlexButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.justify ? props.justify : "flex-start")};
  flex: 0 0 auto;

  > * + * {
    margin-left: ${(props) => (props.gap ? props.gap + "px" : "15px")};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    justify-content: center;
    flex-direction: column;

    > * + * {
      margin-left: 0;
      margin-top: ${(props) => (props.gap ? props.gap + "px" : "15px")};
    }
  }
`;
