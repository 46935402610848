import styled, { css } from "styled-components";

export const Row = styled.div`
  display: ${(props) => (props.displayBlock ? "block" : "flex")};
  align-items: ${(props) => (props.alignCenter ? "center" : "flex-start")};
  justify-content: ${(props) =>
    props.justify ? props.justify : "space-between"};
  width: 100%;
  flex-wrap: ${(props) => (props.isWrap ? "wrap" : "no-wrap")};
  flex-direction: ${(props) => (props.flipRow ? "row-reverse" : "row")};
  background: ${(props) => props.theme.colours[props.bg] || props.bg};

  > * + * {
    ${(props) =>
      props.flipRow
        ? css`
            margin-right: ${(props) => props.gap && props.gap + "px"};
          `
        : css`
            margin-left: ${(props) => props.gap && props.gap + "px"};
          `}
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-direction: ${(props) =>
      props.noBreak
        ? "row"
        : props.mobileReverse
        ? props.flipRow
          ? "column"
          : "column-reverse"
        : "column"};

    > * + * {
      ${(props) =>
        props.flipRow
          ? css`
              margin-right: ${(props) =>
                props.noBreak ? props.gap + "px" : "0"};
            `
          : css`
              margin-left: ${(props) =>
                props.noBreak ? props.gap + "px" : "0"};
            `}

      ${(props) =>
        props.mobileReverse
          ? css`
              margin-bottom: ${(props) => "calc(" + props.gap + "px / 1.7)"};
            `
          : css`
              margin-top: ${(props) =>
                props.noBreak ? "0" : "calc(" + props.gap + "px / 1.7)"};
            `}
    }
  }
`;
