import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
const Seo = ({ title, description, image, pathname, author, type }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          lang,
          defaultTitle,
          titleTemplate,
          defaultDescription,
          siteUrl,
          defaultImage,
          twitterUsername,
        },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: image || `${siteUrl}${defaultImage}`,
        url: `${siteUrl}${pathname || "/"}`,
        author: author,
        type: type || `website`,
      };
      return (
        <>
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={seo.title}
            titleTemplate={titleTemplate}
          >
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            <meta
              property="og:site_name"
              content="Whole Plumbing & Gas Solutions"
            />
            {seo.url && <meta property="og:url" content={seo.url} />}
            <meta property="og:type" content={seo.type} />
            {seo.author && <meta property="og:author" content={seo.author} />}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
              <meta property="og:description" content={seo.description} />
            )}
            {seo.image && <meta property="og:image" content={seo.image} />}
            <meta name="twitter:card" content="summary_large_image" />
            {twitterUsername && (
              <meta name="twitter:creator" content={twitterUsername} />
            )}
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && (
              <meta name="twitter:description" content={seo.description} />
            )}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
            {seo.title && (
              <meta property="twitter:image:alt" content={seo.title} />
            )}
          </Helmet>
        </>
      );
    }}
  />
);
export default Seo;
Seo.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  author: PropTypes.string,
  type: PropTypes.string,
};
Seo.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  type: null,
};
const query = graphql`
  query SEO {
    site {
      siteMetadata {
        lang
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        defaultImage: image
      }
    }
  }
`;
