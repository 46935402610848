import { createGlobalStyle } from "styled-components";
import { darken } from "polished";

const Styles = createGlobalStyle`

    html {
        font-size: 14px;
        background: #fff;

        @media(max-width: ${(props) => props.theme.breakpoints.desktop}){
            font-size: 13px;
        }

        @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
            font-size: 12px;

            .hideOnMobile {
                display: none !important;
            }

            .showOnMobile {
                display: block !important;
            }

            .-centerOnMobile {
                margin-left: auto;
                margin-right: auto;
            }
        }

        .showOnMobile {
            display: none;
        }

        &.-stopScroll {
            overflow: hidden;
        }

        &.-stopScrollMobile {
            @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
                overflow: hidden;
            } 
        }

        main {
            padding-top: 110px;

            &.-fullPage {
                padding-top: 0;
            }
        }

        body {
            position: relative;
            font-family: "Fira Sans", Helvetica, sans-serif;

            h1, 
            h2, 
            h3,
            .h1, 
            .h2, 
            .h3 {
                font-family: "Fira Sans", Helvetica, sans-serif;

                a {
                    font-family: "Fira Sans", Helvetica, sans-serif;
                }
            }

            h4,
            h5,
            h6,
            .h4,
            .h5,
            .h6  {
                font-family: "Fira Sans", Helvetica, sans-serif;
                margin: 0;
            }

            p,
            li,
            a,
            button,
            input {
                font-family: "Fira Sans", Helvetica, sans-serif;
                line-height: 1.5;
                margin: 0;
            }

            h1, .h1 {
                font-size: 3rem;
                font-weight: 600;
                line-height: 1.3;
            }
            
            h2, .h2  {
                font-size: 2.2rem;
                font-weight: 600;
                line-height: 1.3;
            }
            
            h3, .h3  {
                font-size: 1.5rem;
                font-weight: 600;
                color: ${(props) => props.theme.colours.Primary};
                line-height: 1.5;
                margin-bottom: 5px;

                /* Icons that are associated with a feature */
                > svg {
                    font-size: 35px;
                    margin-bottom: 7px;
                }
            }
            
            h4, .h4  {
                font-size: 1.3rem;
                font-weight: 300;
                line-height: 1.3;

                .link-floating {
                    font-size: 1.3rem !important;
                }

                a {
                    line-height: 1.3;
                }
            }

            h5, .h5  {
                font-size: 0.9rem;
                line-height: 1.3;
                color: ${(props) => props.theme.colours.Primary};
            }

            h6, .h6  {
                font-size: 0.9rem;
                color: #555;
                text-transform: uppercase;
                font-weight: 400;
                line-height: 1.3;
            }

            p, li {
                font-size: 1rem;
                margin-bottom: 10px;
            }

            li {
                line-height: 1.5;
            }

            hr {
                &.-light {
                    background: rgba(255, 255, 255, 0.1);
                }
            }

            .font-bump {

                h1, .h1 {
                    font-size: 3.5rem;
                }
                h2, .h2 {
                    font-size: 2.8rem;
                }
                h3, .h3 {
                    font-size: 2rem;
                }
                h4, .h4 {
                    font-size: 1.6rem;
                }
                h5, .h5 {
                    font-size: 1.2rem;
                }
                h6, .h6 {
                    font-size: 0.9rem;
                }
                p,
                li,
                blockquote {
                    font-size: 1.3rem;
                }
                li {
                    p {
                        margin-bottom: 0;
                    }

                    ul, ol {
                        margin-bottom: 1.5rem;
                    }
                }
                p,
                h2,
                h3,
                h4 {
                    margin-bottom: 20px;
                }
                ul, ol {
                    margin: 0.5rem 0 3rem 2rem;
                }

                a {
                    position: relative;
                    font-weight: 500;
                    border-bottom: solid 2px rgba(0, 173, 239, 0.2);
                    padding-bottom: 2px;
                    transition: border-bottom ${(props) =>
                      props.theme.transitions.med};

                    &:hover {
                        border-bottom: solid 2px rgba(0, 173, 239,1);
                    }
                }

                img,
                .gatsby-image-wrapper,
                hr, 
                blockquote {
                    margin-top: 40px;
                    margin-bottom: 40px;
                }

                .gatsby-image-wrapper {
                    img {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                }

                .post-anchor {
                    opacity: 0;
                    height: 1px;
                    margin: 0;
                    position: relative;
                    top: -60px;
                    user-select: none;
                }
            }

            .-fontLarge {
                font-size: 4.5rem;
                line-height: 1.2;

                @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
                    font-size: 3rem;
                }
            }

            .-fontCallout {
                font-weight: 200;
                font-size: 2.5rem;
            }

            .-fontLight {
                font-weight: 200;
            }

            .-fontNormal {
                font-weight: 400;
            }

            .-fontBold {
                font-weight: 600;
            }

            @media (min-width: 1000px){
                .-fontBump {
                    font-size: 2.5rem;
                }
            }

            .-fontDark {
                color: #031b2d !important;
            }

            .-fontBlue {
                color: ${(props) => props.theme.colours.blue} !important;
            }

            .-fontPrimary {
                color: ${(props) => props.theme.colours.Primary} !important;
            }

            .-textCenter {
                text-align: center;
            }

            .site-anchor {
                position: relative;
                top: -60px;
            }

            .-addShadow {
                filter: drop-shadow(${(props) => props.theme.styles.shadow});
            }

            .-rounded {
                border-radius: ${(props) => props.theme.styles.borderRadius};

                > picture {
                    border-radius: ${(props) =>
                      props.theme.styles.borderRadius};   
                }
            }

            .-center {
                margin-left: auto;
                margin-right: auto;
            }

            .-fullWidth {
                width: 100%;
                max-width: 100%;
            }

            a {
                text-decoration: none;
                color: ${(props) => props.theme.colours.Primary};
                font-weight: 600;

                &.link-arrow {
                    font-weight: 600;
                    display: inline-flex;
                    align-items: center;

                    &.-center {
                        width: auto;
                        justify-content: center;
                    }

                    &.link-floating {
                        svg {
                            top: 2px;
                        }
                    }
                    
                    svg {
                        top: 0;
                        left: 3px;
                        transition: all ease-in-out ${(props) =>
                          props.theme.transitions.med};
                    }

                    &:hover svg {
                        left: 10px;
                    }
                }

                &.link-floating {
                    display: table;
                    font-weight: 600;
                    cursor: pointer;
                    font-size: 1.1rem;
                    border-bottom: solid 2px rgba(0, 173, 239, 0.2);

                    &.link-white {
                        border-bottom: solid 2px rgba(255, 255, 255, 0.2);
                    }
                }

                &.link-white {
                    color: ${(props) => props.theme.colours.white};
                }

                &.link-underline {
                    text-decoration: underline;
                }

                > svg {
                    position: relative;
                }
            }

            ul, ol {
                padding-left: 10px;

                li {
                    margin-bottom: 8px;
                }
            }

            /* Icon Styles */

            .icon-large {
                font-size: 40px;
                margin-bottom: 10px;
            }

            /* Input Styles */
            input, 
            select, 
            button,
            textarea {
                width: 100%;
                height: 55px;
                padding: 15px;
                font-size: 1.1rem;
                color: ${(props) => props.theme.colours.dark} !important;
                box-sizing: border-box;
                border-radius: ${(props) => props.theme.styles.borderRadius};
                border: solid 1px ${(props) => props.theme.colours.greyDarken};
                -webkit-appearance: none;
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0 ${(
                  props
                ) => props.theme.colours.white};
                transition: box-shadow ${(props) =>
                  props.theme.transitions.med}, background-color ${(props) =>
  props.theme.transitions.med};

                &:focus {
                    outline: 0;
                    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 2px ${(
                      props
                    ) => props.theme.colours.inputOutline};
                }

                &::placeholder {
                    color: #555 !important;
                }

                &.-error {
                    border: solid 2px ${(props) => props.theme.colours.red};
                }

                @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
                    height: 45px;
                    padding: 12px;
                    font-size: 1rem;
                } 
            }

            input,
            select,
            textarea {
                position: relative;
                background-color: ${(props) => props.theme.colours.white};
                border: solid 1px ${(props) => props.theme.colours.greyDarken};
                border-radius: ${(props) => props.theme.styles.borderRadius};
                max-width: 100%;
            }

            textarea {
                min-width: 100%;
                max-height: 500px;
                min-height: 200px;
            }

            button {
                cursor: pointer;
                color: ${(props) => props.theme.colours.white} !important;
                font-weight: 600;
                background-color: ${(props) => props.theme.colours.white};
                border: none;

                &.primary {
                    background-color: ${(props) => props.theme.colours.Primary};

                    &:hover {
                        background-color: ${(props) =>
                          darken(0.1, props.theme.colours.Primary)};
                    }
                }

                &.-disabled {
                    pointer-events: none;
                    user-select: none;
                    opacity: 0.5;
                    background-color: ${(props) =>
                      props.theme.colours.greyDarken};
                    color: ${(props) => props.theme.colours.dark} !important;   
                }
            }

            form {
                position: relative;
                opacity: 1;
                pointer-events: all;
                transition: opacity ${(props) => props.theme.transitions.med};

                &.-isLoading {
                    opacity: 0.3;
                    pointer-events: none;
                }

                &.-inline {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    max-width: 750px;

                    > * + * {
                        margin-top: 0;
                        margin-left: 15px;
                    }

                    button {
                        max-width: 200px;
                    }

                    @media(max-width: ${(props) =>
                      props.theme.breakpoints.mobile}){
                        flex-direction: column;

                        > * + * {
                            margin-top: 10px;
                            margin-left: 0;
                        }

                        button {
                            max-width: 100%;
                        }
                    }
                }

                > * + * {
                    margin-top: 30px;
                }

                &.-smallStack {

                    > * + * {
                        margin-top: 10px;
                    }
                }
            } 

            .input-title {
                display: block;
                margin-bottom: 15px;
            }

            .select-wrapper {
                display: block;
                position: relative;
                min-width: 150px;

                select {
                    cursor: pointer;
                    padding: 12px 40px 12px 15px;

                    &:hover {
                        background-color: ${(props) =>
                          props.theme.colours.midGrey};
                    }

                    @media(max-width: ${(props) =>
                      props.theme.breakpoints.mobile}){
                        padding: 12px 40px 12px 12px;
                    } 
                }

                .dropdown-arrow {
                    pointer-events: none;
                    position: absolute;
                    font-size: 1rem;
                    right: 13px;
                    top: 50%;
                    transform: translateY(-50%);

                    &.-dark {
                        color: ${(props) => props.theme.colours.dark};

                          * {
                            color: ${(props) => props.theme.colours.dark};
                          }
                    }
                }
            }

            .domain-tooltip {
                position: absolute;
                display: flex;
                align-items: center;
                right: 1px;
                top: 1px;
                border-radius: 0 5px 5px 0;
                z-index: 999;
                height: calc(100% - 2px);
                padding: 0 15px;
                font-size: 14px;
                background-color: ${(props) => props.theme.colours.midGrey};
                border-left: solid 1px  ${(props) =>
                  props.theme.colours.greyDarken};
                transition: background-color ${(props) =>
                  props.theme.transitions.med};

                p {
                    margin: 0;
                    color: ${(props) => props.theme.colours.midDark};
                    user-select: none;
                    display: flex;
                    align-items: center;

                    > svg {
                        color: ${(props) => props.theme.colours.blue};
                        margin-left: 5px;
                        font-size: 1.2rem;
                    }
                }
            }

            .checkbox-wrapper {
                padding: 20px 30px;

                @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
                    padding: 20px 5px;
                } 

                > label {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: left;
                    cursor: pointer;

                    > div {
                        position: relative;

                        input {
                            height: 25px;
                            width: 25px;
                            border: none;
                            -webkit-appearance: none;
                            box-shadow: none;
                            margin-right: 15px;
                            flex: 0 0 auto;
                            border: solid 1px ${(props) =>
                              props.theme.colours.greyDarken};

                            &:checked  {
                                background-color: ${(props) =>
                                  props.theme.colours.green};
                                border-color: ${(props) =>
                                  props.theme.colours.green};
                            }
                        }

                        .checkmark {
                            position: absolute;
                            top: calc(50% - 2px);
                            font-size: 18px; 
                            transform: translateY(-50%);
                            left: 3px;
                            color: ${(props) => props.theme.colours.white};
                        }
                    }
                }
            }

            /* Custom background styles */
            .ph-blue {
                background: #006B7F;
                background: -webkit-linear-gradient(left top, #006B7F, #009BD6);
                background: -o-linear-gradient(bottom right, #006B7F, #009BD6);
                background: -moz-linear-gradient(bottom right, #006B7F, #009BD6);
                background: linear-gradient(to bottom right, #006B7F, #009BD6);
                background-color: #006B7F;
            }

            .ft-blue {
                background: #00adef;
                background: -webkit-linear-gradient(to right bottom, #00adef, #009eda, #008fc6, #0080b2, #00729e);
                background: -o-linear-gradient(to right bottom, #00adef, #009eda, #008fc6, #0080b2, #00729e);
                background: -moz-linear-gradient(to right bottom, #00adef, #009eda, #008fc6, #0080b2, #00729e);
                background: linear-gradient(to right bottom, #00adef, #009eda, #008fc6, #0080b2, #00729e);
                background-color: #00adef;
            }

            .dp-blue {
                background: #0aa0ed;
                background: -webkit-linear-gradient(to right bottom, #0aa0ed, #008de3, #0079d7, #1065ca, #2850ba);
                background: -o-linear-gradient(to right bottom, #0aa0ed, #008de3, #0079d7, #1065ca, #2850ba);
                background: -moz-linear-gradient(to right bottom, #0aa0ed, #008de3, #0079d7, #1065ca, #2850ba);
                background: linear-gradient(to right bottom, #0aa0ed, #008de3, #0079d7, #1065ca, #2850ba);
                background-color: #0aa0ed;
            }

            .inv-orange {
                background: #ff6600;
                background: -webkit-linear-gradient(to bottom right, #ff6600, #964006);
                background: -o-linear-gradient(to bottom right #ff6600, #964006);
                background: -moz-linear-gradient(to bottom right, #ff6600, #964006);
                background: linear-gradient(to bottom right, #ff6600, #964006);
                background-color: #ff6600;
            }

            .ird {
                background-color: #006A78;
            }

            .ph-blue, 
            .ft-blue, 
            .inv-orange, 
            .ird,
            .dp-blue,
            .-whiteOut  {
                h1, h2, h3, h4, h5, h6, p, b, span, a, svg {
                    color: ${(props) => props.theme.colours.white};
                }
            }

            /* .breadcrumb {
                @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
                    text-align: center;
                }
            } */

            .badge {
                /* pointer-events: none;
                user-select: none; */
                display: inline-table;
                position: relative;
                padding: 8px 15px;
                border-radius: 100px;
                color: ${(props) => props.theme.colours.midDark};
                font-weight: 600;
                background: ${(props) => props.theme.colours.white};
                font-size: 1rem;

                &.-sm {
                    padding: 5px 8px;
                    font-size: 0.8rem;
                }

                &.-lg {
                    padding: 10px 17px;
                    font-size: 1.2rem;
                }

                &.-grey {
                    background-color: ${(props) => props.theme.colours.midGrey};
                    color: ${(props) => props.theme.colours.midDark};
                }

                &.-green {
                    background-color: ${(props) => props.theme.colours.green};
                    color: ${(props) => props.theme.colours.white}; 
                }

                &.-blue {
                    background-color: ${(props) => props.theme.colours.blue};
                    color: ${(props) => props.theme.colours.white}; 
                }

                &.-dark {
                    background-color: ${(props) => props.theme.colours.dark};
                    color: ${(props) => props.theme.colours.white}; 
                }

                &.-clear {
                    background-color: ${(props) => props.theme.colours.white};
                    color: ${(props) => props.theme.colours.dark};
                    box-shadow: ${(props) => props.theme.styles.shadow};
                }

                &.-HolidaysAct, 
                &.-PayrollFinance,
                &.-TeamManagement,
                &.-HiringGrowth,
                &.-ProductUpdate {
                    top: -1px;
                    margin-right: 10px;
                }

                &.-HolidaysAct {
                    background-color: ${(props) =>
                      props.theme.colours.green + "26"};
                    color: ${(props) => props.theme.colours.green};
                    
                }

                &.-PayrollFinance {
                    background-color: ${(props) =>
                      props.theme.colours.blue + "26"};
                    color: ${(props) => props.theme.colours.blue};
                }

                &.-TeamManagement {
                    background-color: ${(props) =>
                      props.theme.colours.orange + "26"};
                    color: ${(props) => props.theme.colours.orange};
                    
                }

                &.-HiringGrowth {
                    background-color: ${(props) =>
                      props.theme.colours.red + "26"};
                    color: ${(props) => props.theme.colours.red};
                    
                }

                &.-ProductUpdate {
                    background-color: ${(props) =>
                      props.theme.colours.yellow + "26"};
                    color: ${(props) => props.theme.colours.yellow};
                }
               
            }

            .banner {
                pointer-events: none;
                user-select: none;
                position: absolute;
                text-align: center;
                width: 150px !important;
                height: 0 !important;
                margin: 0 !important;
                font-size: 0.8rem;
                line-height: 2.7;
                font-weight: 600;
                margin-top: 0;
                top: 27px;
                right: -33px;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
                border-left: 30px solid transparent;
                border-right: 30px solid transparent;

                &.-lightGrey {
                    color: ${(props) => props.theme.colours.midDark};
                    border-bottom: 30px solid ${(props) =>
                      props.theme.colours.lightGrey};
                }

                &.-green {
                    color: ${(props) => props.theme.colours.white};
                    border-bottom: 30px solid ${(props) =>
                      props.theme.colours.green};
                }
            }

            /* Slick Styling */

            .slick-slide {
                padding: 53px 67px;

                @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
                    padding: 0;
                }
            }

            .slick-slide div {
                outline: none !important;
            }

            .slick-dots {
                display: flex !important;
                position: relative;
                bottom: 0;
                margin-top: 30px;

                @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
                    flex-direction: column;

                    > * + * {
                        margin-top: 10px;
                    }
                } 

                > * + * {
                    margin-left: 20px !important;
                }

                li {
                    height: auto;
                    width: calc(100% / 3);
                    margin-right: 0;
                    margin-left: 0;
                    border-radius: ${(props) =>
                      props.theme.styles.borderRadius};
                    background-color: rgba(0, 0, 0, 0);
                    transition: all ${(props) => props.theme.transitions.med};

                    &:hover {
                        background-color: rgba(0, 173, 239, 0.05);    
                    }

                    &.slick-active {
                        background-color: rgba(0, 173, 239, 0.1);
                    }

                    @media(max-width: ${(props) =>
                      props.theme.breakpoints.mobile}){
                        width: 100%;
                        margin-left: 0 !important;
                    } 
                }
            }

            /*Domain Tooltip Hover*/

            /* Add this attribute to the element that needs a tooltip */
            [data-tooltip] {
                position: relative;
                z-index: 50;
                cursor: pointer;
                font-weight: 400 !important;
            }

            /* Hide the tooltip content by default */
            [data-tooltip]:before,
            [data-tooltip]:after {
                visibility: hidden;
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
                filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
                opacity: 0;
                pointer-events: none;
                transition: all ${(props) => props.theme.transitions.med};
            }

            /* Position tooltip above the element */
            [data-tooltip]:before {
                position: absolute;
                bottom: 100%;
                left: 50%;
                min-width: 200px;
                margin-bottom: 5px;
                transform: translateX(-50%);
                padding: 10px;
                -webkit-border-radius: ${(props) =>
                  props.theme.styles.borderRadius};
                -moz-border-radius: ${(props) =>
                  props.theme.styles.borderRadius};
                border-radius: ${(props) => props.theme.styles.borderRadius};
                background-color: ${(props) => props.theme.colours.dark};
                color: ${(props) => props.theme.colours.white};
                content: attr(data-tooltip);
                text-align: center;
                font-size: 1rem;
                line-height: 1.5;
            }

            /* Triangle hack to make tooltip look like a speech bubble */
            [data-tooltip]:after {
                position: absolute;
                bottom: 100%;
                left: 50%;
                margin-left: -5px;
                /*transform: translateX(-50%);*/
                width: 0;
                border-top: 5px solid ${(props) => props.theme.colours.dark};
                border-right: 5px solid transparent;
                border-left: 5px solid transparent;
                content: " ";
                font-size: 0;
                line-height: 0;
            }

            /* Show tooltip content on hover + active */
            [data-tooltip]:hover:before,
            [data-tooltip]:hover:after,
            [data-tooltip]:active:before,
            [data-tooltip]:active:after {
                visibility: visible;
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
                filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
                opacity: 1;
            }
        }
    }
`;

export default Styles;
