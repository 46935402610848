import React from "react";
import { Link } from "gatsby";
import styled, { css } from "styled-components";
import { darken } from "polished";

const ButtonStyles = css`
  cursor: pointer;
  flex: 0 0 auto;
  user-select: none;
  font-size: 1rem;
  font-family: "Fira Sans", Helvetica, sans-serif;
  position: relative;
  text-align: center;
  display: table;
  width: auto;
  border-radius: ${(props) => props.theme.styles.borderRadius};
  color: ${(props) => props.theme.colours.white};
  font-weight: 600;
  text-decoration: none;
  transform: scale(1);
  transition: background-color ${(props) => props.theme.transitions.med},
    transform ${(props) => props.theme.transitions.med};

  /* Colours */

  &.primary {
    background-color: ${(props) => props.theme.colours.Primary};
    color: ${(props) => props.theme.colours.dark};

    &:hover {
      background-color: ${(props) => darken(0.1, props.theme.colours.Primary)};
    }
  }
  &.secondary {
    background-color: ${(props) => props.theme.colours.Secondary};

    &:hover {
      background-color: ${(props) =>
        darken(0.1, props.theme.colours.Secondary)};
    }
  }
  &.yellow {
    color: ${(props) => props.theme.colours.dark};
    background-color: ${(props) => props.theme.colours.yellow};

    &:hover {
      background-color: ${(props) => darken(0.1, props.theme.colours.yellow)};
    }
  }
  &.blue {
    background-color: ${(props) => props.theme.colours.blue};

    &:hover {
      background-color: ${(props) => darken(0.1, props.theme.colours.blue)};
    }
  }
  &.orange {
    background-color: ${(props) => props.theme.colours.orange};

    &:hover {
      background-color: ${(props) => darken(0.1, props.theme.colours.orange)};
    }
  }
  &.darkBlue {
    background-color: ${(props) => props.theme.colours.darkBlue};

    &:hover {
      background-color: ${(props) => darken(0.1, props.theme.colours.darkBlue)};
    }
  }
  &.green {
    background-color: ${(props) => props.theme.colours.green};

    &:hover {
      background-color: ${(props) => darken(0.06, props.theme.colours.green)};
    }
  }
  &.grey {
    color: ${(props) => props.theme.colours.dark};
    background-color: ${(props) => props.theme.colours.grey};

    &:hover {
      background-color: ${(props) => darken(0.1, props.theme.colours.grey)};
    }
  }
  &.white {
    color: ${(props) => props.theme.colours.dark};
    background-color: ${(props) => props.theme.colours.white};

    &:hover {
      background-color: ${(props) => darken(0.1, props.theme.colours.white)};
    }

    svg {
      color: ${(props) => props.theme.colours.dark};

      * {
        color: ${(props) => props.theme.colours.dark};
      }
    }
  }
  &.dark {
    color: ${(props) => props.theme.colours.white};
    background-color: rgba(0, 0, 0, 0.3);

    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  &.greyBlue {
    color: ${(props) => props.theme.colours.blue};
    background-color: ${(props) => props.theme.colours.grey};

    &:hover {
      background-color: ${(props) => darken(0.1, props.theme.colours.grey)};
    }
  }
  &.whiteBlue {
    color: ${(props) => props.theme.colours.blue};
    background-color: ${(props) => props.theme.colours.white};

    &:hover {
      background-color: ${(props) => darken(0.1, props.theme.colours.white)};
    }
  }
  &.-disabled {
    color: ${(props) => props.theme.colours.midDark};
    background-color: ${(props) => props.theme.colours.grey};
    pointer-events: none;
    user-select: none;
  }
  &.-clear {
    background-color: transparent;
  }

  /* Sizing */

  &.-xl {
    font-size: 1.1rem;
    padding: 20px 50px;
  }
  &.-lg {
    padding: 20px 40px;
  }
  &.-md {
    padding: 12px 25px;
  }
  &.-sm {
    padding: 5px 15px;
    font-size: 0.8rem;
  }
  &.-full {
    width: 100%;
  }

  &:active {
    transform: scale(0.97);
    opacity: 0.8;
  }
`;

const StyledButton = styled((props) => <Link {...props} />)`
  ${ButtonStyles}
`;

const StyledLink = styled.a`
  ${ButtonStyles}
`;

export const Button = ({
  to,
  children,
  className,
  onClick,
  state,
  atag,
  externalLink,
  buttonType,
  name,
}) => {
  if (atag) {
    return (
      <StyledLink
        href={to}
        type={buttonType ? buttonType : ""}
        className={className}
        onClick={onClick}
        target={externalLink ? "_blank" : "_self"}
        rel={externalLink && "noopener noreferrer"}
        name={name}
      >
        {children}
      </StyledLink>
    );
  } else {
    return (
      <StyledButton to={to} className={className} state={state} name={name}>
        {children}
      </StyledButton>
    );
  }
};
