/* ---------------------------------------------------------- */
/* COLOURS                                                    */
/* ---------------------------------------------------------- */

const colours = {
  blue: "#00adef",
  darkBlue: "#015575",
  green: "#5eb22e",
  white: "#fff",
  lightGrey: "#f9f9f9",
  midGrey: "#f4f4f4",
  grey: "#eee",
  greyDarken: "#ddd",
  midDark: "#999",
  dark: "#333",
  black: "#000",
  orange: "#ff6600",
  red: "#b22e2e",
  yellow: "#FFAD3D",
  purple: "#6703ad",

  inputOutline: "#f1c09e",

  Primary: "#3F8AC8",
  Secondary: "#06203c",
};

/* ---------------------------------------------------------- */
/* STYLES                                                     */
/* ---------------------------------------------------------- */

const styles = {
  shadow: "0 0 30px rgba(0, 0, 0, 0.2)",
  borderRadius: "5px",
};

/* ---------------------------------------------------------- */
/* BREAKPOINTS                                                */
/* ---------------------------------------------------------- */

const breakpoints = {
  mobile: "850px",
  tablet: "1000px",
  laptop: "1200px",
  desktop: "1440px",
};

/* ---------------------------------------------------------- */
/* SPACING                                                    */
/* ---------------------------------------------------------- */

const spacing = {
  xs: "1rem",
  sm: "2rem",
  md: "4rem",
  lg: "8rem",
  xl: "14rem",
};

/* ---------------------------------------------------------- */
/* TRANSITIONS                                                */
/* ---------------------------------------------------------- */

const transitions = {
  long: ".75s",
  slow: ".5s",
  med: ".25s",
  fast: ".15s",
};

export default {
  colours,
  styles,
  breakpoints,
  spacing,
  transitions,
};
