import React, { useState, useEffect, useCallback } from "react";
import { Link } from "gatsby";
import { IoCall, IoMail } from "react-icons/io5";

import { Wrapper, Row, Button } from "./util";

import { Navigation } from "./css/navigation.js";

import Logo from "../images/logo/WPGS_Full.svg";

const Header = ({ isSticky }) => {
  const [togglingMobile, setToggledMobile] = useState(false);

  const toggleMobile = () => setToggledMobile(!togglingMobile);

  const handleScroll = useCallback(() => {
    var element = document.getElementById("navbar");

    if (window.scrollY > 59) {
      element.classList.add("-scrolled");
    } else {
      element.classList.remove("-scrolled");
    }
  }, []);

  useEffect(() => {
    if (togglingMobile) {
      document.documentElement.classList.add("-stopScrollMobile");
    } else {
      document.documentElement.classList.remove("-stopScrollMobile");
    }

    if (isSticky) {
      window.addEventListener("scroll", handleScroll, {
        passive: true,
      });
    }

    return () => {
      window.removeEventListener("scroll", handleScroll, {
        passive: false,
      });
    };
  }, [handleScroll, isSticky, togglingMobile]);

  return (
    <>
      <Navigation className="main-navigation" id="navbar">
        <Wrapper gap="0">
          <div className="mobile-menu">
            <Link to="/">
              <img className="menu-logo" src={Logo} alt="WPGS" />
            </Link>
            <Row justify="flex-end" gap={25} alignCenter noBreak>
              <h4>
                <a href="tel:0272227775">
                  <IoCall css={{ top: "2px", marginRight: "3px" }} /> 027 222
                  7775
                </a>
              </h4>
              <h4>
                <a href="mailto:druw@wpgs.co.nz">
                  <IoMail css={{ top: "2px", marginRight: "3px" }} />{" "}
                  druw@wpgs.co.nz
                </a>
              </h4>
            </Row>
            {/* <Row alignCenter justify="flex-end" className="menu-mobile-buttons">
              <div
                className="menu-mobile-open"
                onClick={toggleMobile}
                role="button"
                tabIndex="0"
                aria-label="Mobile Menu Toggle"
              >
                <div
                  className={`${
                    togglingMobile ? "-isOpen" : ""
                  } mobile-button-icon`}
                >
                  <div className="button-bar"></div>
                  <div className="button-bar"></div>
                  <div className="button-bar"></div>
                </div>
              </div>
            </Row> */}
          </div>
          <div className={`${togglingMobile ? "-active" : ""} main-menu`}>
            <div className="menu-left">
              <Link to="/">
                <img className="menu-logo" src={Logo} alt="WPGS" />
              </Link>
            </div>
            <div className="menu-center"></div>
            <Row gap={30} className="menu-right">
              <h4>
                <a href="tel:0272227775">
                  <IoCall css={{ top: "2px", marginRight: "3px" }} /> 027 222
                  7775
                </a>
              </h4>
              <h4>
                <a href="mailto:druw@wpgs.co.nz">
                  <IoMail css={{ top: "2px", marginRight: "3px" }} />{" "}
                  druw@wpgs.co.nz
                </a>
              </h4>
            </Row>
          </div>
        </Wrapper>
        <div
          onClick={toggleMobile}
          className={`${togglingMobile ? "-active" : ""} nav-overlay`}
        />
      </Navigation>
    </>
  );
};

export default React.memo(Header);
