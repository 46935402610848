import styled from "styled-components";

export const Box = styled.div`
  position: relative;
  display: ${(props) => (props.isFlex ? "flex" : "block")};
  width: ${(props) => (props.size ? props.size + "%" : "auto")};
  align-items: center;

  > * + * {
    margin-top: ${(props) => (props.gap ? props.gap + "px" : "0")};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: ${(props) => (props.size ? "100%" : "auto")};
    text-align: ${(props) => (props.centerOnMobile ? "center" : "inherit")};

    img {
      margin-left: ${(props) => (props.centerOnMobile ? "auto" : "")};
      margin-right: ${(props) => (props.centerOnMobile ? "auto" : "")};
    }

    > * + * {
      margin-top: ${(props) =>
        props.gap ? "calc(" + props.gap + "px / 1.5)" : "0"};
    }
  }
`;
